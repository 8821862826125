import {Link} from "gatsby";
import React from "react";
import ROUTES from "../routes";
import * as styles from "./index.module.scss";

const FAQ_LIST = [
    {
        title: "What is Fearn?",
        description: <>Fearn is a fully integrated business solutions software where you can manage your entire
            reselling business. Fearn was designed to be a centralized hub to manage your inventory, sales,
            crosslisting, delisting, analytics, bookkeeping, and more! Save time, earn more, and grow your
            business! <br/><br/>For more information be sure to check out our <Link
                className={styles.globalShippingLink} to={ROUTES.features}>Features</Link> page.</>,
    },
    {
        title: "What makes Fearn different from other reselling software?",
        description: `While other reselling softwares solve a particular pain point, Fearn alleviates them all. 
                  Instead of having a separate software for bookkeeping, analytics, inventory management, and crosslisting, Fearn allows you to consolidate and streamline your reselling systems all in one place.`,
    },
    {
        title: "Can I try out Fearn before purchasing?",
        description: <>Absolutely! Every user gets to experience Fearn through a 14 day risk-free trial. You're not even
            required to enter a credit card.<br/><br/>
            Our free trial comes with access to every feature so you know exactly what you're getting when you choose to
            invest in your business.</>,
    },

    {
        title: "Can I use Fearn outside of the United States?",
        description: "Unfortunately, no. Currently Fearn only supports users and stores that are in the United States and use USD.",
    },
    {
        title: "Does Fearn violate any terms or policies?",
        description: <>No. Fearn is fully compliant with the supported marketplaces' terms and
            conditions.<br/><br/> Fearn is not a "bot" and will not put your account at risk.</>,
    },
    {
        title: "Can I use Fearn on my mobile device?",
        description: <>Fearn is designed to be used on desktop or laptop computers. <br/><br/>
            We did, however, develop our Picking/Shipping tasks screen to be fully responsive (mobile friendly) so that
            you can have easy access to that information while doing shipping.</>,
    },
    {
        title: "Is Fearn safe and secure?",
        description: <>
            Your security is of utmost importance to us. That is why we included the following security measures (but not limited to):<br/><br/>
            - Cookie-Based Authentication<br/><br/>
            - Cross-Site Request Forgery Prevention<br/><br/>
            - Secure Salted Password Hashing<br/><br/>
            - OAuth authorization for Google and marketplaces eBay, Etsy<br/><br/>
            - XSS prevention<br/><br/>
            - SQL injections prevention
        </>,
    },
    {
        title: "How much does Fearn cost per month?",
        description: <>Sometimes, simplicity is best. That is why we have an easy-to-understand <Link
            className={styles.globalShippingLink} to={ROUTES.pricing}>Pricing</Link> structure that is based on your
            active inventory and draft requirements.<br/><br/>
            Pay $35, $50, or $65 per month depending on the size of your business. No add-ons and no hidden fees.</>,
    },
    {
        title: "Can I upgrade or downgrade my subscription?",
        description: <>Yes. When upgrading your subscription in the middle of a billing period, Fearn will add a
            pro-rated charge based on the amount of the upgrade and the amount of days remaining.<br/><br/>
            For example, if you wanted to upgrade from Business to Unlimited and you are halfway through your billing
            cycle, you would only be charged $7.50 instead of the full $15.<br/><br/> When downgrading, the change will
            go into
            effect the next billing period.</>,
    },
    {
        title: "What payment methods does Fearn accept?",
        description: `Fearn currently accepts all major credit cards.`,
    },
];

export default FAQ_LIST;